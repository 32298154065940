[data-theme-version="dark"] {
    &[data-sidebar-style="mini"] {
		.dlabnav .metismenu li > ul{
			background-color: $dark-card;
		}
		 .dlabnav .metismenu a{
			&:hover{
				background-color:var(--primary);
			}
		 }
		
	}
	
	.fixed-content-box{
		background-color: $dark-card;
		.head-name{
			background:$dark-card;
			color: $white;
			border-color:$d-border; 
		}
		& + .header + .dlabnav{
			background-color: $d-bg;
		}
	}
	 &[data-layout="vertical"]{
		&[data-sidebar-position="fixed"]{
			.dlabnav{
				border-color:$d-border;
			}
		}
		&[data-sidebar-style="compact"] {
			.dlabnav{
				.metismenu{
					&>li{ 
						&.mm-active,
						&:hover {
							&>a {
								background:transparent;
								i{
									color:var(--primary);
									background:var(--rgba-primary-1);
								}
							}
						}
						a{
							&>i{
								color:rgba($white,0.3);
							}
						}
					}
				}
			}
		}
	}
    .dlabnav {
		.header-profile > a.nav-link{
			border-color:$d-border;
			.header-info span{
				color:$white;
			}
		}
        .metismenu {
    
    
            &>li {
		
                &>a {
                    color: darken($color: $white, $amount: 30%);
                }
    
                &:hover, 
                &:focus, 
                &.mm-active {
    
                    &>a {
                        color: $white!important;
						//background:var(--rgba-primary-1); 
						background-color:var(--primary);
                        &::after {
                            border-top: 0.3125rem solid #fff;
							border-left: 0.3125rem solid #fff;
							border-bottom: 0.3125rem solid transparent;
							border-right: 0.3125rem solid transparent;
                        }
						i{
							color:$white;
						}
                    }
                }
    
                &.mm-active {
					
                    ul {
						
                        ul {
                            background-color: transparent;
                        }
                    }
                }
            }
    
            //one step dropdown
            ul  {
                
				&:after{
					background-color:$d-border;
				}
				
                a {
    
                    &:hover,
                    &:focus, 
                    &.mm-active {
                        color: $white!important;
                    } 
					&:before{
						background-color: $white;
					}
					
                }
            }
            
            a {
                color: darken($color: $white, $amount: 30%)!important;
            }
    
            .has-arrow {
                &:after {
                    border-top: 0.3125rem solid #b3b3b3;
					border-left: 0.3125rem solid #b3b3b3;
					border-bottom: 0.3125rem solid transparent;
					border-right: 0.3125rem solid transparent;
                }
            }
        }
    }
    
    

}