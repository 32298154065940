.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
  }
  
  .page-item {
    margin: 0 5px;
    height: 40px;
    width: 40px;
    border: 0px solid #cfcfcf;
    cursor: pointer;    
    background-color: transparent;
    color: #757575;
    border-radius: 100%;
  }
  
  .page-item:hover {
    /* background-color: #f1f1f1; */
  }
  
  .page-item.active {
    background-color: #f54749;
    color: #fff;
    border-color: #f54749;
    border: 0px red solid 
  }
  
  .page-item:disabled {
    /* color: #ccc;
    cursor: not-allowed; */
  }
  