@media (min-width: 62rem) {
    [data-container="wide-boxed"] {
        #main-wrapper {
            max-width: 92.5rem;
            margin: 0 auto;
			box-shadow: 0 0 1.875rem 0 rgba(0,0,0,0.1);
				
            @at-root [direction="rtl"]#{&} {
                text-align: right;
            }
        }
		.hamburger{
			left:-5rem;
			
			
		}
		.nav-header .hamburger.is-active{
			//left:-74px;
			left:-20px;
		}
		.dlab-img {
			img{
				width:16.938rem;
				bottom:-1.063rem;
			}
		}
		.dlabnav{
			box-shadow:none;
		}	
		.tracker-bx iframe{
			width:19.063rem;
		}
		
		
    }
}

    
    
@include custommq($min: 84.375rem) {
    [data-layout="vertical"][data-sidebar-style="overlay"][data-container="wide-boxed"][data-header-position="fixed"] {
        .header {
            width: 92.5rem;
        }
    }
}

@include custommq($min: 75rem, $max: 100rem) {
    [data-layout="vertical"][data-sidebar-style="overlay"][data-container="wide-boxed"][data-header-position="fixed"] {
        .header {
            width: 92.5rem;
        }
    }
}
[data-sidebar-style="compact"][data-header-position="fixed"][data-container="wide-boxed"][data-layout="vertical"] {
    .header {
        width: 92.5rem;
    }
}

[data-header-position="fixed"][data-layout="horizontal"][data-container="wide-boxed"] {
    .dlabnav {
        max-width: 92.5rem;
    }
} //ok

[data-layout="vertical"][data-container="wide-boxed"][data-header-position="fixed"],
[data-layout="horizontal"][data-container="wide-boxed"][data-header-position="fixed"] {
    .header {
        width: 92.5rem;
    }
}
[data-layout="horizontal"][data-container="wide-boxed"][data-header-position="fixed"][data-sidebar-style="full"],
[data-layout="horizontal"][data-container="wide-boxed"][data-header-position="fixed"][data-sidebar-style="icon-hover"]{
	 .dlabnav .metismenu > li > a{
		margin-bottom:1rem;
	 }
	 .header-right .header-profile2 .dropdown-menu .dropdown-item{
		padding:0.438rem 0.625rem 0.625rem 1.7rem;
	 }
}

[data-layout="horizontal"][data-container="wide-boxed"][data-header-position="fixed"][data-sidebar-style="mini"] {
    .header {
        width: 92.5rem;
    }
}
[data-layout= "horizontal"][data-container="wide-boxed"][data-header-position="fixed"][data-sidebar-style="full"],[data-layout= "horizontal"][data-container="wide-boxed"][data-header-position="fixed"][data-sidebar-style="modern"],[data-layout= "horizontal"][data-container="wide-boxed"][data-header-position="fixed"][data-sidebar-style="icon-hover"]{
	.header-bx{
		width:45.125rem;
		margin-left:18px;
		.search-drop{
			left:141px!important;
			 .card{
				&.tag-bx{
					width:45.125rem!important;
				}
			
			} 
		}
	}
	.header-right .header-profile2 .dropdown-menu .dropdown-item{
		padding:0.438rem 0.625rem 0.625rem 1.7rem;
	 }
	
}
[data-layout="horizontal"][data-container="wide-boxed"][data-header-position="fixed"][data-sidebar-style="compact"]{


	.header-right .header-profile2 .dropdown-menu .dropdown-item{
		padding:0.438rem 0.625rem 0.625rem 1.7rem;
	 }
}


[data-container="wide-boxed"] {
    .metismenu.fixed {
        left: auto;
        max-width: 92.5rem;
    }

    .page-titles {
        margin-bottom: 3rem;
        padding: 0.9375rem 0.9375rem;
    }

    .content-body .container-fluid {
        padding: 2.5rem;
    }
}

[data-container="wide-boxed"][data-layout="vertical"] {
    .page-titles {
        margin-left: 0;
        margin-right: 0;
    }
}

[data-layout="vertical"][data-container="wide-boxed"][data-sidebar-position="fixed"][data-header-position="static"][data-sidebar-style="overlay"] {
    .nav-header {
        position: absolute;
    }

    .menu-toggle {
        .dlabnav {
            position: absolute;
        }
    }
}

[data-container="wide-boxed"][data-sidebar-position="fixed"][data-layout="horizontal"] {
    .dlabnav.fixed {
        left: auto;
        max-width: 92.5rem;
    }
}