// Ckeditor
.ck.ck-editor{
	
	// toolbar__items
	.ck.ck-toolbar__items{
		.ck.ck-toolbar__separator{
			display: none;
		}
	}
	.ck.ck-button {
		padding: 0.375rem 0.5rem;
		background-color: #fff;
		color: $headings-color;
		font-weight: 900;
		border: 0.0625rem solid #F0F0F1;
	}
	.ck.ck-toolbar{
		background-color: #f7f7f7;
		border: 0;
		padding: 0.3125rem 0.625rem;
	}
	.ck.ck-toolbar__separator{
		display: none;
	}
	.ck-content{
		background-color: #f7f7f7;
		border-width: 0.0625rem 0 0;
		border-color: rgba(0,0,0,0.1);
		min-height: 12.5rem;
		border: 0.0625rem solid #F0F0F1!important;
	}	
}
.ck-editor__editable{
	background-color:#f7f7f7!important;	
}
.ck.ck-reset.ck-editor{	
	border: 0.0625rem solid #F0F0F1;
}