.feedback-bx{
	ul{
		li{
			margin-right:0.5rem;
		}
	}
}
.pagination-title{
	span{
		color:#3D4152;
	}
}